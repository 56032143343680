<template>
  <div class="
    h-full w-full
    py-2 md:py-4
    gap-0.5
    flex flex-col
  ">

    <!-- Header -->
    <div class='container'>
    <div class='
      container relative
      flex md:flex-col
      justify-between items-center
      border border-gradient
      border-x-0 border-t-0
    '>
      <div :class="`
        ${title_class}
        ${$route.name === 'About' ? `
          md:h-16 lg:h-20
          md:opacity-100
        ` : `
          md:h-0
          md:opacity-0
        `}
        w-full
        flex
        md:justify-center
        ease-out duration-400
        overflow-hidden
      `">
        <img :src="require(`@/assets/images/hi_im_matthew.jpg`)"
        draggable="false"
        class="
          h-8 md:h-16 lg:h-20
          object-cover
          select-none
          md:py-3 lg:py-4
          opacity-60
          pointer-events-none select-none
        " />
      </div>
      <NavMenu>
        <NavLink v-for="link in nav_links" :key="link"
        :link="link"
        :active="$route.name && $route.name.includes(link)" />
      </NavMenu>
    </div>
    </div>

    <!-- Content -->
    <ViewContainer :loading="app_state.value.loading">
      <router-view />
    </ViewContainer>
    
    <!-- Footer -->
    <div class='container'>
    <div class="
      container
      flex flex-col
      items-center
      border border-gradient
      border-x-0 border-b-0
    ">
      <div class=" pt-2
        h-8
        flex
        items-center
        gap-4
      ">
        <a :href="link.url" target="_blank"
        v-for="link in contact_links" :key="link.key"
        class="
          h-8 w-8
          flex
          p-1
        ">
          <!-- <div class="w-full h-full border border-blue" /> -->
          <svg viewBox="0 0 24 24" width="100%" height="100%">
            <path :d="icon_paths[link.icon]"
            fill="#3b82f6" />
          </svg>
        </a>
      </div>
    </div>
    </div>
  </div>
</template>

<script setup> /* Composition API */
import { onMounted, onUnmounted, onUpdated, inject } from 'vue'
import icon_paths from '@/data/icon_paths.json';
import { NavMenu, NavLink } from '@/components/nav/_exports'
import ViewContainer from '@/components/global/ViewContainer.vue'

const app_state = inject('app_state');
const doc = document.documentElement;
const nav_links = ['About', 'Portfolio', 'Journal'];
const contact_links = [
  {
    url: 'mailto:09maharding@gmail.com',
    icon: 'email'
  },
  {
    url: 'https://www.linkedin.com/in/matthew-harding-528312179/',
    icon: 'linkedin'
  },
];
let title_class = "transition-none";

function setAppHeight() {
  doc.style.setProperty('height', `${window.innerHeight}px`);
}

onMounted(() => {
  window.addEventListener('resize', setAppHeight);
  setAppHeight();
  document.querySelector("#app").classList.add("bg-white", "text-black")
  if(app_state.value) app_state.loading.stop(300 + Math.random() * 200);
})
onUpdated(() => {
  title_class = "transition-all";
})
onUnmounted(() => {
  window.removeEventListener('resize', setAppHeight);
})
</script>

<style>
@import '@/styling/index.css';
</style>