import { reactive, readonly } from "vue";

const value = reactive({
  loading: true,
});

let loading_timmeout;

const loading = {
  start: () => value.loading = true,
  stop: (delay = 0) => {
    if (loading_timmeout) {
      window.clearTimeout(loading_timmeout);
    }
    loading_timmeout = setTimeout(() => value.loading = false, delay)
  },
}

export default {
  value: readonly(value),
  loading
};