import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import app_state from "@/stores/app_state";

const app = createApp(App);

app.use(router);
app.provide('app_state', app_state);

app.mount('#app');
