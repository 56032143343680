<template>
  <div :ref="(el) => nav_el = el"
  class="
    md:w-full
    flex
    justify-center items-end
  ">
    <!-- <h2 class="
      sm:text-lg lg:text-xl
      md:hidden
      font-light tracking-widest
    ">
      {{$route.name}}
    </h2> -->
    <button :ref="(el) => button_el = el"
    @click.prevent="() => navOpen = !navOpen"
    :class="`
      ${navOpen ? 'nav-open' : ''}
      md:hidden flex
      justify-end items-center
      w-8 h-8
      mobile-menu
    `">
      <span class="w-5 bg-blue" />
      <span class="w-5 bg-blue" />
      <span class="w-5 bg-blue" />
    </button>
    <div class="
      absolute left-0 right-0 top-full
      md:static
      z-10
    ">
      <ul :class="`
        ${navOpen ? 'h-28 ease-out border' : 'h-0 ease'}
        md:h-fit
        container
        flex flex-col md:flex-row
        justify-center items-end
        gap-2
        md:gap-8 md:p-0
        transition-height duration-200
        overflow-hidden
        bg-white mt-px
        border-gradient
        border-x-0 border-t-0
      `">
        <slot></slot>
      </ul>
      <span class="
        flex
        w-full h-px/2
      " />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute();

let navOpen = ref(false);
let nav_el = ref(null);
let button_el = ref(null);

function listenerFunction(e) {
  if(!button_el.value.contains(e.target)) {
    navOpen.value = nav_el.value.contains(e.target)
  }
}
watch(() => navOpen.value, () => {
  if(navOpen.value) {
    window.addEventListener('click', listenerFunction)
    window.addEventListener('touchstart', listenerFunction)
  } else {
    window.removeEventListener('click', listenerFunction)
    window.removeEventListener('touchstart', listenerFunction)
  }
})
watch(() => route.name, () => {
  if(navOpen.value) navOpen.value = false;
})
</script>

<style scoped>
  /* Lines Default */
  .mobile-menu span {
    position: absolute;
    height: 1px;
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    -o-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    -webkit-transition: .2s ease-in-out;
    -moz-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
  }
  .mobile-menu span:nth-child(1) {
    top: calc(50% - 0.5rem);
  }
  .mobile-menu span:nth-child(2) {
    top: 50%;
  }
  .mobile-menu span:nth-child(3) {
    top: calc(50% + 0.5rem);
  }
  /* Lines Active */
  .mobile-menu.nav-open span {
    top: 50%;
  }
  .mobile-menu.nav-open span:nth-child(1) {
    -webkit-transform: translate(0%, 0%) rotate(-45deg);
    -moz-transform: translate(0%, 0%) rotate(-45deg);
    -o-transform: translate(0%, 0%) rotate(-45deg);
    transform: translate(0%, 0%) rotate(-45deg);
  }
  .mobile-menu.nav-open span:nth-child(2) {
    opacity: 0;
  }
  .mobile-menu.nav-open span:nth-child(3) {
    -webkit-transform: translate(0%, 0%) rotate(45deg);
    -moz-transform: translate(0%, 0%) rotate(45deg);
    -o-transform: translate(0%, 0%) rotate(45deg);
    transform: translate(0%, 0%) rotate(45deg);
  }
</style>