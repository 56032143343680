<template>
  <li class="
    text-header
    sm:text-lg lg:text-xl
    font-light
    tracking-widest
  ">
    <!-- <span v-if="props.active"
    class="text-blue">
      {{ props.link }}
    </span> -->
    <router-link :to="{ name: props.link }"
    @click="(e) => {
      e.stopPropagation();
      app_state.loading.start();
      app_state.loading.stop(300 + Math.random() * 200);
    }"
    :class="`${props.active ? 'text-blue' : ''}`">
      {{ props.link }}
    </router-link>
  </li>
</template>
<script setup>
import { defineProps, inject } from 'vue'
const props = defineProps([
  'link',
  'active'
]);
const app_state = inject('app_state');
</script>