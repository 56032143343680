<template>
  <div :ref="(el) => Block.circle([el])"
  class='w-full h-full' />
</template>

<script setup>
import { Block } from 'notiflix/build/notiflix-block-aio';

Block.init({
  svgColor: '#3b82f6',
  backgroundColor: '#fafafa',
  zindex: 0
});
</script>