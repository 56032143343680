import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'About',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () => import('@/views/PortfolioView.vue')
  },
  {
    path: '/portfolio/:id',
    name: 'Portfolio - Project',
    component: () => import('@/views/ProjectView.vue'),
    props: true
  },
  {
    path: '/journal',
    name: 'Journal',
    component: () => import('@/views/JournalView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((route) => {
  window.document.title = `${route.name} - Matthew` ?? 'maharding';
})

export default router
